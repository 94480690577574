import React from 'react';

import { RoleEmployee, RoleUser, PhotoRequestStatus } from '@homesusa/core';
import { useHasRole, useAuth } from '@homesusa/auth';
import { PhotoRequestContext } from 'modules/photo-requests/contexts';
import {
  SavePhotoRequest,
  CancelPhotoRequest,
  ReportError,
  DeletePhotoRequest,
  MessagePhotographer,
  AssignPhotographer,
  RequestTripCharge,
  CancelTripCharge,
  RejectTripCharge,
  ApproveTripCharge,
  SubmitPhotoRequest,
  ApprovePhotoRequest,
  PhotosRejectedRequest,
  UnlockPhotoRequest,
  NotifyRequestEdits
} from '../components/buttons';
import {
  CompanyEmployeeStatuses,
  MlsAdminStatuses,
  PhotographerStatuses
} from 'core/utils/user-action-statuses';

type toolbarButtonsType = Record<
  string,
  {
    component: React.FunctionComponent;
    show: boolean;
  }
>;

export const useToolbarButtons = (): toolbarButtonsType => {
  const {
    photoRequest: { status, submittedBy, isUnlocked }
  } = React.useContext(PhotoRequestContext);
  const { isMlsAdministrator, hasUserRole, hasEmployeeRole } = useHasRole();
  const { user: userProfile } = useAuth();

  const toolbarButtons = React.useMemo(() => {
    if (
      PhotoRequestStatus.Cancelled == status ||
      hasEmployeeRole([RoleEmployee.Readonly, RoleEmployee.CompanyAdminReadonly])
    ) {
      return {};
    }

    const buttons: toolbarButtonsType = {
      save: {
        show:
          (isMlsAdministrator && MlsAdminStatuses.Save.includes(status)) ||
          (hasUserRole([RoleUser.Photographer]) && PhotographerStatuses.Save.includes(status)) ||
          (hasEmployeeRole([RoleEmployee.CompanyAdmin, RoleEmployee.SalesEmployee]) &&
            CompanyEmployeeStatuses.Save.includes(status)),
        component: SavePhotoRequest
      },
      submitRequest: {
        show:
          PhotoRequestStatus.Assigned == status &&
          hasUserRole([RoleUser.MLSAdministrator, RoleUser.Photographer]),
        component: SubmitPhotoRequest
      },
      approveRequest: {
        show:
          PhotoRequestStatus.AwaitingApproval == status &&
          (isMlsAdministrator ||
            hasEmployeeRole([RoleEmployee.CompanyAdmin]) ||
            userProfile?.id == submittedBy),
        component: ApprovePhotoRequest
      },
      unlockRequest: {
        show: PhotoRequestStatus.PhotosApproved == status && isMlsAdministrator && !isUnlocked,
        component: UnlockPhotoRequest
      },
      notifyRequestEdited: {
        show:
          PhotoRequestStatus.PhotosApproved == status &&
          hasUserRole([RoleUser.Photographer]) &&
          isUnlocked,
        component: NotifyRequestEdits
      },
      cancelRequest: {
        show:
          (isMlsAdministrator && MlsAdminStatuses.Cancel.includes(status)) ||
          (hasUserRole([RoleUser.Photographer]) && PhotographerStatuses.Cancel.includes(status)),
        component: CancelPhotoRequest
      },
      deleteRequest: {
        show: isMlsAdministrator && MlsAdminStatuses.Delete.includes(status),
        component: DeletePhotoRequest
      },
      assignPhotographer: {
        show: isMlsAdministrator && MlsAdminStatuses.AssignPhotographer.includes(status),
        component: AssignPhotographer
      },
      messagePhotographer: {
        show:
          (isMlsAdministrator && MlsAdminStatuses.MessagePhotographer.includes(status)) ||
          (hasEmployeeRole([RoleEmployee.CompanyAdmin, RoleEmployee.SalesEmployee]) &&
            CompanyEmployeeStatuses.MessagePhotographer.includes(status)),
        component: MessagePhotographer
      },
      tripChargeRequest: {
        show:
          (isMlsAdministrator && MlsAdminStatuses.RequestTripCharge.includes(status)) ||
          (hasUserRole([RoleUser.Photographer]) &&
            PhotographerStatuses.RequestTripCharge.includes(status)),
        component: RequestTripCharge
      },
      tripChargeApprove: {
        show: isMlsAdministrator && MlsAdminStatuses.ApproveOrCancelTripCharge.includes(status),
        component: ApproveTripCharge
      },
      tripChargeCancel: {
        show: isMlsAdministrator && MlsAdminStatuses.ApproveOrCancelTripCharge.includes(status),
        component: CancelTripCharge
      },
      tripChargeReject: {
        show: isMlsAdministrator && status == PhotoRequestStatus.TripChargePending,
        component: RejectTripCharge
      },
      reportError: {
        show:
          isMlsAdministrator ||
          hasEmployeeRole([RoleEmployee.CompanyAdmin, RoleEmployee.SalesEmployee]) ||
          hasUserRole([RoleUser.Photographer]),
        component: ReportError
      },
      rejectPhotos: {
        show:
          status == PhotoRequestStatus.AwaitingApproval &&
          (userProfile?.id == submittedBy ||
            isMlsAdministrator ||
            hasEmployeeRole([RoleEmployee.CompanyAdmin])),
        component: PhotosRejectedRequest
      }
    };

    return buttons;
  }, [status, submittedBy, userProfile, isMlsAdministrator, hasUserRole, hasEmployeeRole]);

  return toolbarButtons;
};
