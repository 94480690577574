import React, { Fragment } from 'react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { PersonOutlineOutlined } from '@mui/icons-material';

import { Photographer } from 'core/interfaces';
import { IconButton } from 'core/components';
import { Modal } from './modal.component';
import { useGetPhotographers, useAssignPhotographer } from '../../hooks';

export function AssignPhotographer(): JSX.Element {
  const { loading, users } = useGetPhotographers();
  const [showModal, setShowModal] = React.useState(false);
  const [userSelected, setUserSelected] = React.useState<Photographer>();
  const assignPhotographer = useAssignPhotographer();

  if (loading) {
    return <Fragment></Fragment>;
  }
  return (
    <Fragment>
      <IconButton onClick={(): void => setShowModal(true)} title="Assign Photographer">
        <PersonOutlineOutlined />
      </IconButton>
      <Modal
        dialogProps={{
          open: showModal,
          onClose: (): void => setShowModal(false)
        }}
        title="Select photographer"
        actionButton={{
          text: 'Assign',
          method: async (): Promise<void> => {
            await assignPhotographer(userSelected);
            setShowModal(false);
          }
        }}
      >
        <List>
          {users.map((user) => (
            <ListItemButton
              component="a"
              selected={userSelected && user.id === userSelected.id}
              onClick={(): void => {
                setUserSelected(user);
              }}
              key={user.id}
            >
              <ListItemText primary={`${user.firstName} ${user.lastName}`} />
            </ListItemButton>
          ))}
        </List>
      </Modal>
    </Fragment>
  );
}
