import React, { Fragment } from 'react';
import { FormControl, InputLabel, Select as NativeSelect } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { getUserCompanies } from 'core/services/user.service';
import { Company } from '@homesusa/core';
import { AuthCompanyContext, useAuth } from '@homesusa/auth';

export function SelectCompany({
  onSelect,
  defaultSelected,
  size
}: {
  defaultSelected?: string;
  onSelect: (value: Company | undefined) => void;
  size?: 'small' | 'medium';
}): JSX.Element {
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const { user } = useAuth();
  const [companies, setCompanies] = React.useState<Company[]>();
  const label = 'Company';

  React.useLayoutEffect(() => {
    if (user) {
      getUserCompanies(user.id).then((companies) => setCompanies(companies));
    }
  }, [user?.id, currentMarket]);

  if (!companies) {
    return <Fragment></Fragment>;
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel size={size === 'medium' ? 'normal' : size}>{label}</InputLabel>
      <NativeSelect
        native
        onChange={(event: SelectChangeEvent): void => {
          const company = companies.find((m) => m.id === (event.target.value as string));
          onSelect(company);
        }}
        label={label}
        inputProps={{
          defaultValue: defaultSelected
        }}
        size={size}
      >
        <option aria-label="None" value="" />
        {companies.map((company) => (
          <option key={company.id} value={company.id}>
            {company.name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}
